import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid } from 'react-device-detect';
import Cookies from 'js-cookie';
import { useApp } from 'context/AppContext';
import appDownloadLogo from 'assets/images/app-download-logo.png';
import chromeLogo from 'assets/images/chrome-logo.png';
import { Component, Banner, Row, AppLogo, Title, AppLink, BtnClose } from './ModalApp.styled';

const ModalApp = () => {
    const { installAppModal } = useApp();
    const { t } = useTranslation();

    const [showModal, setModal] = useState(false);
    const [isShown, setShown] = useState(true);

    const modalIsShown = Cookies.get('appModal');

    const closeModal = () => {
        setModal(false);
        setShown(true);
    }

    useEffect(() => {
        if (!modalIsShown) {
            setShown(false);
        }
    }, []);

    useEffect(() => {
        if (installAppModal && isAndroid && !isShown) {
            Cookies.set('appModal', '1', { expires: 1 });
            setModal(true);
            setShown(true);
        }
    }, [installAppModal, showModal]);

    return (
        <Component isActive={showModal}>
            <Banner>
                <Title>{t('title.store_btn_block')}</Title>
                <Row>
                    <AppLogo>
                        <img src={appDownloadLogo} alt={t('btn.open')}/>
                        <span>{ t('title.header_main') } life</span>
                    </AppLogo>
                    <AppLink
                        href="intent://dating.life.com.by/#Intent;scheme=https;package=by.life.dating2;end"
                    >
                        { t('btn.open') }
                    </AppLink>
                </Row>
                <Row>
                    <AppLogo>
                        <img src={chromeLogo} alt=""/>
                        <span>Google Chrome</span>
                    </AppLogo>
                    <BtnClose onClick={closeModal}>{ t('btn.continue') }</BtnClose>
                </Row>
            </Banner>
        </Component>
    );
};

export default ModalApp;