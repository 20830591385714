// <---- This page is temporary solution for approving android app ----------->
export const Approve = () => {
    return (
        <div>
            <p>
                In accordance with ticket number 3-9369000034160, we declare that <a href="https://play.google.com/store/apps/details?id=by.life.dating2">https://play.google.com/store/apps/details?id=by.life.dating2</a> is our android application that uses a WebView for the dating.life.com.by domain ONLY for user authorization.
            </p>
        </div>
    );
}

export const APPROVE_ROUTE = '/approve';