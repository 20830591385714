import React, { createContext, useContext, useEffect, useState, useMemo, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { initReactI18next, useTranslation } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import { authAPI, profileAPI, configApi, PROJECT_TOKEN, X_DEVICE_BY, CONTENT_TYPE, IShortProfile, ErrorStatus } from 'api';
import { Path } from 'routes';
import { useAppSelector } from 'store';
import {CookieNames, Language, LP_STATUS_12, LP_STATUS_4, UserStatus} from 'config/constants';
import { findFromQuery } from 'utils/findFromQuery';
import { getCurrentLang, AppLang } from 'utils/getCurrentLang';
import { useTitle } from 'hooks/useTitle';
import {useFirebase} from "../hooks/useFirebase";
import { Modal, useModal } from 'components/Modal/Modal';

interface ICtrlMenu {
    active: boolean
    toggle: () => void
}

interface ICtrlLanguage {
    value: AppLang
    toggle: () => void
}

interface IAppContext {
    isInitializing: boolean
    userProfile: IShortProfile
    userStatus: UserStatus
    title: string
    menu: ICtrlMenu
    language: ICtrlLanguage
    clearAuth: () => void
    captchaKey: string
    flowUrl: string
    errorStatus: ErrorStatus
    installAppModal: boolean
    showErrorModal: (message:string, isRedirectToMain?:boolean) => void
    updateMessageCount: () => void
}

i18n
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        lng: getCurrentLang(),
        fallbackLng: Language.Ru,
        react: {
            useSuspense: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
    })
    .catch((e) => console.error(e));

const AppContext = createContext<IAppContext>({} as IAppContext);

export const useApp = () => useContext(AppContext);

export const AppProvider:FC = ({ children }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const title = useTitle();
    const [modal, showModal, hideModal] = useModal();


    useFirebase();

    const { status: errorStatus } = useAppSelector(state => state.error);

    const { data: dataConfig, isLoading: isLoadingConfig } = configApi.useGetConfigQuery();

    const [
        loginBySid,
        { data: dataAuth, isLoading: isLoadingAuth, error: errorAuth }
    ] = authAPI.useLoginBySidMutation();

    const [
        getShortProfile,
        { data: dataProfile, isLoading: isLoadingProfile }
    ] = profileAPI.useLazyGetShortProfileQuery();

    const [menuIsActive, setMenuActivity] = useState(false);
    const [userStatus, setUserStatus] = useState(UserStatus.Guest);
    const [currentLang, setCurrentLang] = useState<AppLang>(getCurrentLang);
    const [user, setUser] = useState({} as IShortProfile);
    const [appIsInitializing, setAppInitializing] = useState(true);

    const toggleLang = async () => {
        const lang = (currentLang === Language.Ru) ? Language.Be : Language.Ru;
        Cookies.set(CookieNames.Language, lang);
        await i18n.changeLanguage(lang);
        setCurrentLang(lang);
        window.location.reload();
    }

    const toggleMenu = () => {
        setMenuActivity((state) => !state);
    }

    const clearAuth = () => {
        Cookies.remove(CookieNames.AuthToken);
        Cookies.remove(CookieNames.Sid);
        Cookies.remove(CookieNames.GeoMessageWarned);
        Cookies.remove(CookieNames.PHPSession);
        setUserStatus(UserStatus.Guest);
    }

    const updateMessageCount = () => {
        getShortProfile();
    }

    const showErrorModal = (message:string, isRedirectToMain?:boolean) => {
        showModal({
            title: t('modal.title.error'),
            body: message,
            resolve: {
                label: t('modal.btn.clear'),
                handler: () => {
                    hideModal();
                    if (isRedirectToMain) {
                        window.location.href = `${Path.public.main}`
                    }
                    if (pathname.includes('/chat')) {
                        window.location.href = `${Path.private.dialogs}`
                    }
                },
            }
        })
    }

    const menuCtrl = useMemo(() => {
        return { active: menuIsActive, toggle: toggleMenu };
    }, [menuIsActive]);

    const langCtrl = useMemo(() => {
        return { value: currentLang, toggle: toggleLang };
    }, [currentLang]);

    const captchaKey = useMemo(() => {
        return dataConfig?.recaptcha_v3_site_key || '';
    }, [dataConfig]);

    const flowUrl = useMemo(() => {
        return dataConfig?.flow_url ? `${dataConfig.flow_url}&backurl=${window.location.href}` : '/';
    }, [dataConfig]);

    const installAppModal = useMemo(() => {
        if (dataConfig) {
            return dataConfig.popup_app_install;
        }

        return false;
    }, [dataConfig]);

    useEffect(() => {
        Cookies.set(CookieNames.ContentType, CONTENT_TYPE);
        Cookies.set(CookieNames.Accept, CONTENT_TYPE);
        Cookies.set(CookieNames.ProjectToken, PROJECT_TOKEN);
        Cookies.set(CookieNames.Device, X_DEVICE_BY);
    }, []);

    useEffect(() => {
        Cookies.set(CookieNames.Language, currentLang);
    }, [currentLang]);

    useEffect(() => {
        const sid = findFromQuery('sid');
        const status = findFromQuery('status');
        const authToken = Cookies.get(CookieNames.AuthToken);

        if (status && (status === LP_STATUS_4 || status === LP_STATUS_12)) {
            return;
        }

        if (sid) {
            clearAuth();
            loginBySid({ sid });
        } else if (authToken) {
            getShortProfile();
            setUserStatus(UserStatus.Authorized);
        } else {
            Cookies.remove(CookieNames.AuthToken);
            Cookies.remove(CookieNames.Sid);
            Cookies.remove(CookieNames.PHPSession);
            setUserStatus(UserStatus.Guest);
        }
    }, []);

    useEffect(() => {
        if (dataAuth && dataAuth.result) {
            getShortProfile();
            Cookies.set(CookieNames.AuthToken, `Bearer ${dataAuth.api_token}`);
            setUserStatus(UserStatus.Authorized);
            navigate(pathname);
        } else if (errorAuth) {
            Cookies.remove(CookieNames.AuthToken);
            setUserStatus(UserStatus.Guest);
            navigate(Path.public.main);
        }

        if (dataAuth && !dataAuth.profile_completed && pathname !== Path.private.registration) {
            navigate(Path.private.registration);
        }
    }, [dataAuth, errorAuth]);

    useEffect(() => {
        if (dataProfile) {
            setUser(dataProfile);
            if (!dataProfile.profile_completed && pathname !== Path.private.registration) {
                navigate(Path.private.registration);
            }
        }
    }, [dataProfile, pathname]);

    useEffect(() => {
        window.document.title = title;
    }, [title]);

    useEffect(() => {
        if (
            errorStatus === 401
            || errorStatus === 402
            || errorStatus === 403
            || errorStatus === 409
        ) {
            clearAuth();
        }
    }, [errorStatus]);

    useEffect(() => {
        setAppInitializing(isLoadingAuth || isLoadingProfile || isLoadingConfig);
    }, [isLoadingAuth, isLoadingProfile, isLoadingConfig]);

    return (
        <AppContext.Provider value={{
            userProfile: user,
            isInitializing: appIsInitializing,
            menu: menuCtrl,
            language: langCtrl,
            userStatus,
            title,
            captchaKey,
            flowUrl,
            clearAuth,
            errorStatus,
            installAppModal,
            showErrorModal,
            updateMessageCount,
        }}>
            <>
                <Modal
                    active={modal.active}
                    title={modal.title}
                    body={modal.body}
                    resolve={modal.resolve}
                />
                { children }
            </>
        </AppContext.Provider>
    );
}